$(document).ready(function () {
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".header-bottom").fadeIn(function () {
			$(".header-bottom").css({
				display: "flex",
			});
		});
		$("body").on("click", ".filter-item .filter-title", function () {
			$(this).next().slideToggle();
			$(this).parent().toggleClass("is-toggle");
		});
	}

	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
	breadcrumbTitle();
	toggleFilter();
	paginationFilter();
	appendHtml();
	backToTop();
	stickyNav();
	$("body").on("click", ".filter-item  .ajaxlink", function () {
		if ($(this).hasClass("active")) {
			$(".filter-item .ajaxlink").removeClass("active");
			$(this).removeClass("active");
		} else {
			$(".filter-item .ajaxlink").removeClass("active");
			$(this).addClass("active");
		}
	});
});

$(document).ready(function () {
	jQuery(function ($) {
		"use strict";
		var counterUp = window.counterUp["default"]; // import counterUp from "counterup2"
		var $counters = $(".counter");
		/* Start counting, do this on DOM ready or with Waypoints. */
		$counters.each(function (ignore, counter) {
			var waypoint = new Waypoint({
				element: $(this),
				handler: function () {
					counterUp(counter, {
						duration: 2000,
						delay: 10,
					});
					this.destroy();
				},
				offset: "top-in-view",
			});
		});
	});
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function appendHtml() {
	if ($("body").hasClass("news-detail-page")) {
		if ($(".news-detail-lastest-wrap").length) {
			$(".news-detail-lastest-wrap").appendTo(".news-detail-lastest");
		}
		if ($(".news-detail-social-wrap").length) {
			$(".news-detail-social-wrap").appendTo(".service-network-wrap ");
		}
	}
}

function paginationFilter() {
	$(".news-pagination-wrap").each(function (index) {
		let object = $(this).find(".col").length;
		if (object > 6) {
			var items = $(this).find(".col");
			var numItems = items.length;
			var perPage = 6;
			items.slice(perPage).hide();
			$(this)
				.find(".pagination-wrap")
				.attr("id", "pagination-container-" + index);
			$("#pagination-container-" + index).pagination({
				items: numItems,
				itemsOnPage: perPage,
				displayedPages: 3,
				onPageClick: function (pageNumber) {
					var showFrom = perPage * (pageNumber - 1);
					var showTo = showFrom + perPage;
					items.hide().slice(showFrom, showTo).show();
				},
			});
		}
	});
}
function toggleFilter() {
	$(".filter-display-wrapper").each(function () {
		let x = 6;
		$(this).find(".col").slice(0, 6).show();
		let size = $(this).find(".col").length;
		if (size < x || size == x) {
			$(this).find(".ajax-center-wrap").addClass("disble-btn");
		}
		$(".filter-display-wrapper")
			.find(".btn-ajax-link")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-display-wrapper")
					.find(".col")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).slideUp();
				}
			});
	});
	$(".filter-8-wrapper").each(function () {
		let x = 8;
		$(this).find(".col").slice(0, 8).show();
		let size = $(this).find(".col").length;
		if (size < x || size == x) {
			$(this).find(".ajax-center-wrap").addClass("disble-btn");
		}
		$(".filter-8-wrapper")
			.find(".btn-ajax-link")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 8;
				$(this)
					.parents(".filter-8-wrapper")
					.find(".col")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).slideUp();
				}
			});
	});
	$(".filter-item .filter-option-wrapper").each(function () {
		let x = 5;
		$(this).find("li").slice(0, 5).show();
		let size = $(this).find("li").length;
		if (size < x || size == x) {
			$(this).find(".btn-vm").addClass("disble-btn");
		}
		$(".filter-option-wrapper")
			.find(".btn-vm")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 5;
				$(this)
					.parents(".filter-option-wrapper")
					.find("li")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).addClass("disble-btn");
				}
			});
	});
}

function stickyNav() {
	if ($(".sticky-nav").length) {
		$(".sticky-nav").scrollToFixed({
			zIndex: 99,
			marginTop: $("header").outerHeight(),
		});
	}

	$(window)
		.scroll(function () {
			var scrollDistance = $(window).scrollTop();
			// Assign active class to nav links while scolling
			$(".about-section-wrapper .about-section-id").each(function (i) {
				if (
					$(this).position().top -
						$(".sticky-nav").outerHeight() -
						$("header").outerHeight() -
						1 <=
					scrollDistance
				) {
					$(".sticky-nav li.active").removeClass("active");
					$(".sticky-nav li").eq(i).addClass("active");
				}
			});
		})
		.scroll();
	$(".sticky-nav a").on("click", function (event) {
		if (this.hash !== "") {
			let offset =
				$("header").outerHeight() + $(".sticky-nav").outerHeight();
			var hash = this.hash;
			$("html, body").animate(
				{
					scrollTop: $(hash).offset().top - offset,
				},
				800,
				function () {
					// window.location.hash = hash;
				}
			);
		} // End if
	});
}

function breadcrumbTitle() {
	if ($(".breadcrumb-grey").length) {
		let txt = $(".breadcrumb-grey ol li:nth-child(2) span").text();
		$(".breadcrumb-title").text(txt);
	} else {
	}
}

function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".mobile-navbar-primary-menu").toggleClass("menu-toggle-mobile");
		$hamburger.toggleClass("is-active");
	});

	$(".mobile-navbar-primary-menu .title em").on("click", function () {
		$(this).parent().next().slideToggle();
	});

	$(".search-toggle").on("click", function () {
		$(".searchbox").toggleClass("open");
		$(".search-toggle").toggleClass("icon-change");
		if ($(".header-top-banner").hasClass("hidden")) {
			$(".searchbox.open").css({
				top: "55px",
			});
		}
		event.preventDefault();
		$('.searchbox  > input[type="text"]').focus();
	});

	$(".searchbox, .searchbox .close").on("click keyup", function (event) {
		if (
			event.target == this ||
			event.target.className == "close" ||
			event.keyCode == 27
		) {
			$(this).removeClass("open");
			$(".search-toggle").removeClass("icon-change");
		}
	});
	$(".side-navigation-nav .title em").on("click", function () {
		if ($(this).parents("li").hasClass("active")) {
			$(".side-navigation-nav .drop-down").slideUp();
			$(".side-nav li").removeClass("active");
		} else {
			$(".side-navigation-nav .drop-down").slideUp();
			$(".side-nav li").removeClass("active");
			$(this).parent().next().slideDown();
			$(this).parents("li").addClass("active");
		}
	});
	$(".chief-item .btn-view-toggle").on("click", function () {
		if ($(this).parents(".chief-item").hasClass("is-toggle")) {
			$(".chief-item .list-wrap").slideUp();
			$(".chief-item").removeClass("is-toggle");
		} else {
			$(".chief-item .list-wrap").slideUp();
			$(".chief-item").removeClass("is-toggle");
			$(this).parents(".chief-item").find(".list-wrap").slideDown();
			$(this).parents(".chief-item").addClass("is-toggle");
		}
	});
	$(".product-view .view-horizontal").on("click", function () {
		$(".product-list-wrap .col").attr(
			"class",
			"col w-full horizontal-item"
		);
		$(".product-view .view-option").removeClass("active-view");
		$(this).addClass("active-view");
	});

	$(".product-view .view-vertical").on("click", function () {
		$(".product-list-wrap .col").attr(
			"class",
			"col  w-1/2 sm:w-4/12 vertical-item"
		);
		$(".product-view .view-option").removeClass("active-view");
		$(this).addClass("active-view");
	});
	//Mobile Toggle only
	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("open-sub")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("open-sub");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("open-sub");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("open-sub");
			}
		});

		$(".side-navigation-nav .text ").on("click", function () {
			$(this).next().slideToggle();
		});
		$(".mobile-product-filter-wrap .remove-filter ").on(
			"click",
			function () {
				$(this)
					.parents(".mobile-product-filter-wrap")
					.removeClass("is-toggle");
			}
		);
		$(".toggle-modile-filter ").on("click", function () {
			$(".mobile-product-filter-wrap").toggleClass("is-toggle");
		});
	}
	$(".read-more-wrap").each(function () {
		if ($(this).find(".txt-wrap").outerHeight() < 300) {
			$(this).find(".btn-read-more").remove();
		} else {
			$(this).find(".txt-wrap").css({
				height: "300",
			});
		}
		let button = $(this).find(".btn-read-more");
		let content = $(this);
		button.on("click", function () {
			content.toggleClass("active");
		});
	});

	//Toggle item
	$(".support-toggle-wrapper").each(function (index, element) {
		let $this = $(this);
		$this.addClass("toggle-instance-" + index);
		$(".toggle-instance-" + index + " .toggle-item").click(function () {
			if ($(this).hasClass("is-toggle")) {
				console.log("istoggle");
				$(this).find(".article").slideUp();
				$(this).removeClass("is-toggle");
			} else {
				$(this).find(".article").slideDown();
				$(this).addClass("is-toggle");
				console.log("no");
			}
		});
	});
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	$(".swiper-partner .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("partner-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-partner-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-partner-" + index);

		var swiper = new Swiper(".partner-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 4000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				600: {
					slidesPerView: 3,
				},
				1024.1: {
					slidesPerView: 3,
					grid: {
						rows: 2,
					},
				},
				1280.1: {
					slidesPerView: 4,
					grid: {
						rows: 2,
					},
				},
			},
			navigation: {
				nextEl: ".btn-next-partner-" + index,
				prevEl: ".btn-prev-partner-" + index,
			},
		});
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	var documentSwiper = new Swiper(".document-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		spaceBetween: 30,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 4,
			},
			1024: {
				slidesPerView: 5,
			},
			1440: {
				slidesPerView: 6,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".document-swiper .nav-arrow-next",
			prevEl: ".document-swiper .nav-arrow-prev",
		},
	});
	var solutionSwiper = new Swiper(".home-solution-swiper .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		spaceBetween: 30,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				spaceBetween: 30,
				slidesPerView: 3,
			},
			1280: {
				spaceBetween: 64,
				slidesPerView: 3,
			},
			1440.1: {
				spaceBetween: 128,
				slidesPerView: 3,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".home-solution-swiper .nav-arrow-next",
			prevEl: ".home-solution-swiper .nav-arrow-prev",
		},
	});

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".home-alliance-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-alliance-" + index);

		var swiper = new Swiper(".instance-alliance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 10,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 2,
				},

				768: {
					slidesPerView: 3,
				},
				1024: {
					slidesPerView: 4,
				},
				1280: {
					slidesPerView: 5,
				},
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				450: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});

	var projectThumbs = new Swiper(".project-swiper-thumb  .swiper", {
		spaceBetween: 15,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
			},
			420: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 5,
			},
			1024.1: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var projecttMain = new Swiper(".project-swiper-main  .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: projectThumbs,
		},
	});

	var productThumbs = new Swiper(".swiper-product-thumb  .swiper", {
		spaceBetween: 15,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			420: {
				slidesPerView: 4,
				direction: "horizontal",
			},
			768: {
				slidesPerView: 5,
				direction: "horizontal",
			},
			1024.1: {
				slidesPerView: 4,
				direction: "vertical",
			},
			1440: {
				slidesPerView: 5,
				direction: "vertical",
			},
		},
		navigation: {
			nextEl: ".swiper-product-thumb .nav-next",
			prevEl: ".swiper-product-thumb .nav-prev",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".swiper-product-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: productThumbs,
		},
	});

	var videoThumbs = new Swiper(".swiper-video-thumb  .swiper", {
		spaceBetween: 15,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
				direction: "horizontal",
			},
			576: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			1024: {
				slidesPerView: 3,
				direction: "vertical",
			},
		},
		pagination: {
			el: ".swiper-pagination",
			type: "progressbar",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var videoMain = new Swiper(".swiper-video-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: videoThumbs,
		},
	});

	var historyThumbs = new Swiper(".swiper-history-thumb  .swiper", {
		spaceBetween: 15,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
				direction: "horizontal",
			},
			576: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			1280: {
				slidesPerView: 1.12,
				direction: "vertical",
			},
		},
		navigation: {
			nextEl: ".swiper-history-thumb .nav-next",
			prevEl: ".swiper-history-thumb .nav-prev",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var historyMain = new Swiper(".swiper-history-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: historyThumbs,
		},
	});
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}
window.onscroll = function () {
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		$(".back-to-top").addClass("show");
	} else {
		$(".back-to-top").removeClass("show");
	}
};

function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}
